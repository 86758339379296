:root, :host {
  --ol-background-color: white;
  --ol-accent-background-color: #f5f5f5;
  --ol-subtle-background-color: #80808040;
  --ol-partial-background-color: #ffffffbf;
  --ol-foreground-color: #333;
  --ol-subtle-foreground-color: #666;
  --ol-brand-color: #0af;
}

.ol-box {
  box-sizing: border-box;
  border: 1.5px solid var(--ol-background-color);
  background-color: var(--ol-partial-background-color);
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: var(--ol-partial-background-color);
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-line-inner {
  border: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
  text-align: center;
  will-change: contents, width;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-bar-inner {
  display: flex;
}

.ol-scale-step-marker {
  background-color: var(--ol-foreground-color);
  float: right;
  z-index: 10;
  width: 1px;
  height: 15px;
}

.ol-scale-step-text {
  z-index: 11;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
  font-size: 10px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
  font-size: 12px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid var(--ol-foreground-color);
  height: 10px;
  position: relative;
}

.ol-scale-singlebar-even {
  background-color: var(--ol-subtle-foreground-color);
}

.ol-scale-singlebar-odd {
  background-color: var(--ol-background-color);
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ol-viewport canvas {
  all: unset;
  overflow: hidden;
}

.ol-viewport {
  touch-action: pan-x pan-y;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: var(--ol-subtle-background-color);
  border-radius: 4px;
  position: absolute;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: var(--ol-subtle-foreground-color);
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  background-color: var(--ol-background-color);
  border: none;
  border-radius: 2px;
  width: 1.375em;
  height: 1.375em;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-weight: normal;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover, .ol-control button:focus {
  outline: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  flex-flow: row-reverse;
  align-items: center;
  max-width: calc(100% - 1.3em);
  display: flex;
  bottom: .5em;
  right: .5em;
}

.ol-attribution a {
  color: var(--ol-subtle-foreground-color);
  text-decoration: none;
}

.ol-attribution ul {
  color: var(--ol-foreground-color);
  text-shadow: 0 0 2px var(--ol-background-color);
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-width: inherit;
  vertical-align: middle;
  max-height: 2em;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: var(--ol-partial-background-color);
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid var(--ol-subtle-foreground-color);
  width: 150px;
  height: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: var(--ol-subtle-background-color);
}

.ol-overviewmap-box {
  border: 1.5px dotted var(--ol-subtle-foreground-color);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

html {
  --hover-color: #00becd;
  --primary-color: #003278;
  --light-gray: #f6f6f6;
  width: 100%;
  height: 100%;
  font-family: Work Sans, sans-serif;
  overflow: hidden;
}

input, button {
  font-family: Work Sans, sans-serif;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

#map {
  flex: 1;
}

#map .ol-zoom, #map .geoloc {
  background-color: #0000;
  transition: all .3s;
}

#map .ol-zoom button, #map .geoloc button {
  color: var(--primary-color);
  fill: var(--primary-color);
  border: 2px solid #0000;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  padding: 4px;
  font-size: 15pt;
  line-height: 13pt;
}

#map .ol-zoom button:hover, #map .geoloc button:hover {
  border: 2px solid var(--hover-color);
  color: var(--hover-color);
  cursor: pointer;
  outline: none;
}

#map .ol-zoom {
  top: 10px;
  left: auto;
  right: 10px;
}

#map .geoloc {
  bottom: 10px;
  left: auto;
  right: 10px;
}

#global-panel {
  z-index: 10;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
  padding-top: 60px;
  font-size: 11pt;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

#global-panel .header-image {
  height: 175px;
}

#global-panel.hide {
  left: -400px;
}

#global-panel .legend {
  font-size: 12pt;
}

#global-panel .legend .legend-item {
  align-items: center;
  margin: 40px 20px;
  display: flex;
}

#global-panel .legend .legend-item .img {
  text-align: center;
  height: 60px;
  margin-right: 10px;
  font-size: 9pt;
  font-style: italic;
}

#global-panel .legend .legend-item .label {
  flex-direction: column;
  flex: 1;
  display: flex;
}

#global-panel .legend .legend-item .label .label-title {
  color: var(--primary-color);
  font-size: 15pt;
  font-weight: bold;
}

#ae-panel.opened + #map .ol-zoom, #ae-panel.opened + #map .geoloc {
  right: 410px;
}

#ae-panel {
  z-index: 10;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  width: 400px;
  font-size: 11pt;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -400px;
  overflow-y: auto;
}

#ae-panel.opened {
  right: 0;
}

#ae-panel .close-zone {
  background-color: #fff;
  justify-content: flex-end;
  display: flex;
  position: sticky;
  top: 0;
}

#ae-panel .close-zone #close-ae-panel-btn {
  margin: 15px;
}

#ae-panel .part-block {
  background-color: var(--light-gray);
  border-radius: 20px;
  margin: 15px;
  padding: 20px;
  font-size: 12pt;
}

#ae-panel .part-block .description-row:not(:last-child) {
  margin-bottom: 20px;
}

#ae-panel .part-block .sub-title {
  color: var(--primary-color);
  font-weight: bold;
}

#ae-panel .part-block .value ul {
  margin-top: 0;
  margin-left: 10px;
  padding-left: 10px;
}

#ae-panel .part-title {
  color: var(--primary-color);
  margin-bottom: 10px;
  font-size: 15pt;
  font-weight: bold;
}

#ae-panel .ae-part .ae-header {
  margin-bottom: 10px;
  display: flex;
}

#ae-panel .ae-part .ae-header .img {
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
}

#ae-panel .ae-part .ae-header .ae-header-label {
  flex: 1;
  margin-left: 10px;
}

#ae-panel .ae-part .ae-header #ae-label-title {
  color: var(--primary-color);
  font-size: 15pt;
  font-weight: bold;
}

#ae-panel #ae-impacted-streets .street {
  margin-bottom: 5px;
}

#ae-panel #ae-impacted-streets .num {
  color: #666;
  font-size: 9pt;
}

.contact-part {
  text-align: center;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
  font-size: 12pt;
}

.contact-part .tel, .contact-part .contact-link {
  color: #fff;
  font-size: 13pt;
  font-weight: bold;
  display: inline-block;
}

.contact-part .tel:hover, .contact-part .contact-link:hover {
  color: var(--hover-color);
}

.contact-part .tel {
  text-decoration: none;
}

.search-submit-btn, #open-global-panel-btn, #close-ae-panel-btn {
  cursor: pointer;
  color: var(--primary-color);
  fill: var(--primary-color);
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 15pt;
  font-weight: bold;
  display: flex;
  background-color: #0000 !important;
}

.search-submit-btn svg, #open-global-panel-btn svg, #close-ae-panel-btn svg {
  width: 25px;
}

.search-submit-btn:hover, #open-global-panel-btn:hover, #close-ae-panel-btn:hover {
  color: var(--hover-color);
  fill: var(--hover-color);
}

.search-pan {
  z-index: 20;
  border: 2px solid var(--primary-color);
  background-color: #fff;
  border-radius: 20px;
  width: 350px;
  height: 30px;
  margin: 10px;
  padding: 5px 10px 5px 15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.search-pan .search-bar {
  flex: 1;
  align-items: center;
  display: flex;
}

.search-pan #search-input {
  border: none;
  outline: none;
  flex: 1;
  height: 100%;
  font-size: 15pt;
  background-color: #fff !important;
}

.search-pan #search-result-list {
  border: 2px solid var(--primary-color);
  background-color: #fff;
  border-radius: 10px;
  max-width: 340px;
  padding: 5px;
  font-size: 11pt;
  position: absolute;
  top: 100%;
}

.search-pan #search-result-list .search-result-item {
  cursor: pointer;
  align-items: center;
  padding: 10px;
  display: flex;
}

.search-pan #search-result-list .search-result-item:hover {
  background-color: var(--hover-color);
  color: #fff;
}

.search-pan #search-result-list .search-result-item:hover:before {
  color: #fff;
}

.search-pan #search-result-list:empty {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

@media only screen and (width <= 767px) {
  #map .ol-zoom {
    top: initial;
    right: initial;
    bottom: 10px;
    left: 10px;
  }

  #ae-panel.opened + #map .geoloc {
    right: 10px;
  }

  #ae-panel {
    top: initial;
    width: initial;
    max-height: 80%;
    bottom: -100%;
    left: 0;
    right: 0;
  }

  #ae-panel.opened {
    bottom: 0;
  }
}
/*# sourceMappingURL=index.6766c846.css.map */
