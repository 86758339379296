@import "../node_modules/ol/ol.css";

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "Work Sans", sans-serif;

  --hover-color: #00BECD;
  --primary-color: #003278;
  --light-gray: #F6F6F6;
}

input, button {
  font-family: "Work Sans", sans-serif;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  display: flex;
  position: relative;
}

#map {
  flex: 1;

  .ol-zoom, .geoloc {
    transition: all 300ms;
    background-color: transparent;

    button {
      color: var(--primary-color);
      fill: var(--primary-color);
      font-size: 15pt;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      border: 2px solid transparent;
      margin-bottom: 5px;
      line-height: 13pt;
      padding: 4px;

      &:hover {
        border: 2px solid var(--hover-color);
        outline: none;
        color: var(--hover-color);
        cursor: pointer;
      }
    }
  }

  .ol-zoom {
    top: 10px;
    left: auto;
    right: 10px;
  }

  .geoloc {
    bottom: 10px;
    left: auto;
    right: 10px;
  }
}

#global-panel {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  width: 400px;
  transition: all 300ms;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 11pt;
  padding-top: 60px;

  .header-image {
    height: 175px;
  }

  &.hide {
    left: -400px;
  }

  .legend {
    font-size: 12pt;

    .legend-item {
      display: flex;
      align-items: center;
      margin: 40px 20px;

      .img {
        margin-right: 10px;
        font-size: 9pt;
        height: 60px;
        text-align: center;
        font-style: italic;
      }

      .label {
        flex: 1;
        display: flex;
        flex-direction: column;

        .label-title {
          color: var(--primary-color);
          font-size: 15pt;
          font-weight: bold;
        }
      }
    }
  }
}

#ae-panel.opened + #map .ol-zoom {
  right: 410px;
}

#ae-panel.opened + #map .geoloc {
  right: 410px;
}

#ae-panel {
  position: absolute;
  z-index: 10;
  background-color: white;
  top: 0;
  bottom: 0;
  right: -400px;
  width: 400px;
  overflow-y: auto;
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 11pt;
  
  &.opened {
    right: 0;
  }

  .close-zone {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    background-color: white;

    #close-ae-panel-btn {
      margin: 15px;
    }
  }

  .part-block {
    background-color: var(--light-gray);
    border-radius: 20px;
    font-size: 12pt;
    margin: 15px;
    padding: 20px;

    .description-row:not(:last-child) {
      margin-bottom: 20px;
    }

    .sub-title {
      font-weight: bold;
      color: var(--primary-color);
    }

    .value {
      ul {
        margin-left: 10px;
        margin-top: 0;
        padding-left: 10px;
      }
    }
  }

  .part-title {
    font-size: 15pt;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
 
  .ae-part {
    .ae-header {
      display: flex;
      margin-bottom: 10px;

      .img {
        margin-bottom: 10px;
        height: 45px;
        width: 45px;
      }

      .ae-header-label {
        flex: 1;
        margin-left: 10px;
      }

      #ae-label-title {
        font-size: 15pt;
        font-weight: bold;
        color: var(--primary-color);
      }
    }
  }

  #ae-impacted-streets {
    .street {
      margin-bottom: 5px;
    }

    .num {
      font-size: 9pt;
      color: #666;
    }
  }
}


.contact-part {
  padding: 10px;
  text-align: center;
  font-size: 12pt;
  color: white;
  background-color: var(--primary-color);
  border-radius: 20px;
  margin: 20px;
  
  .tel, .contact-link {
    display: inline-block;
    font-weight: bold;
    font-size: 13pt;
    color: white;

    &:hover {
      color: var(--hover-color);
    }
  }

  .tel {
    text-decoration: none;
  }
}

.search-submit-btn,
#open-global-panel-btn,
#close-ae-panel-btn {
  font-size: 15pt;
  font-weight: bold;
  background-color: transparent !important;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  color: var(--primary-color);
  fill: var(--primary-color);

  svg {
    width: 25px;
  }

  &:hover {
    color: var(--hover-color);
    fill: var(--hover-color);
  }
}

.search-pan {
  position: absolute;
  z-index: 20;
  background-color: white;
  top: 0;
  left: 0;
  margin: 10px;
  width: 350px;
  height: 30px;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  display: flex;
  padding: 5px 10px 5px 15px;
  
  .search-bar {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  #search-input {
    border: none;
    flex: 1;
    height: 100%;
    background-color: white !important;
    outline: none;
    font-size: 15pt;
  }

  #search-result-list {
    position: absolute;
    top: 100%;
    background-color: white;
    font-size: 11pt;
    max-width: 340px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    padding: 5px;

    .search-result-item {
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--hover-color);
        color: white;
      }

      &:hover::before {
        color: white;
      }
    }

    &:empty {
      display: none;
    }
  }
}

/* stylelint-disable property-no-vendor-prefix */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* stylelint-enable property-no-vendor-prefix */

@media only screen and (width <= 767px) {
  #map .ol-zoom {
    top: initial;
    right: initial;
    bottom: 10px;
    left: 10px;
  }

  #ae-panel.opened + #map .geoloc {
    right: 10px;
  }

  #ae-panel {
    top: initial;
    left: 0;
    right: 0;
    bottom: -100%;
    max-height: 80%;
    width: initial;

    &.opened {
      bottom: 0;
    }
  }
}